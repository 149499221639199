import React from 'react';

function ChatOption({ onLoginStart, onLoginComplete }) {
    return (
        <div className="flex justify-right p-3">
            <button 
                className="bg-slate-600 text-slate-200 py-2 px-4 rounded"
                onClick={onLoginStart}
            >
                Let's go!
            </button>
        </div>
    );
}

export default ChatOption;
