import React, { useState, useEffect } from 'react';
import { FaBars, FaUser, FaQuestionCircle, FaLink, FaPhoneAlt, FaYoutube, FaStore } from 'react-icons/fa'; // Importing Font Awesome icons
import './Sidebar.css'; // Ensure this file exists and is correctly linked

const Sidebar = () => {
    const [isOpen, setIsOpen] = useState(true);
    const [showProfileModal, setShowProfileModal] = useState(false);
    const [showQuestionModal, setShowQuestionModal] = useState(false);
    const [ownerDetails] = useState(JSON.parse(localStorage.getItem('userDetails')) || {
        firstName: '',
        lastName: '',
        email: '',
        title: '',
        industry: ''
    });

    useEffect(() => {
        // Default to collapsed state on mobile
        const handleResize = () => {
            if (window.innerWidth <= 768) {
                setIsOpen(false);
            } else {
                setIsOpen(true);
            }
        };

        window.addEventListener('resize', handleResize);
        handleResize(); // Set initial state

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const toggleSidebar = () => {
        setIsOpen(!isOpen);
    };

    const handleOutsideClick = (event, closeModal) => {
        if (event.target.className === 'modal') {
            closeModal();
        }
    };

    return (
        <div className={`sidebar-container ${isOpen ? 'open' : 'closed'}`}>
            <button className="hamburger" onClick={toggleSidebar}>
                <FaBars /> {/* Hamburger icon */}
            </button>
            {isOpen && (
                <div className="sidebar-content">
                    <div className="logo-section">
                        <img src="https://cdn.shopify.com/s/files/1/0673/6556/8734/files/logo_name.png?v=1719877192" alt="Logo" className="logo" />
                    </div>
                    <div className="menu-items">
                        <a href="https://www.demystifyai.org" className="menu-item" target="_blank" rel="noopener noreferrer">
                            <FaLink className="menu-icon" /> Demystify AI
                        </a>
                        <a href="#" className="menu-item" onClick={() => setShowProfileModal(true)}>
                            <FaUser className="menu-icon" /> My Profile
                        </a>
                        <a href="https://www.demystifyaisolutions.com/" className="menu-item" target="_blank" rel="noopener noreferrer">
                            <FaStore className="menu-icon" /> Our Solutions
                        </a>
                        <a href="https://www.demystifyaisolutions.com/contact" className="menu-item" target="_blank" rel="noopener noreferrer">
                            <FaPhoneAlt className="menu-icon" /> Book a Call
                        </a>
                        <a href="#" className="menu-item" onClick={() => setShowQuestionModal(true)}>
                            <FaQuestionCircle className="menu-icon" /> Questions?
                        </a>
                    </div>
                    <div className="footer-section">
                        <button className="upgrade-btn" onClick={() => window.open('https://www.youtube.com/@brandonwong6274', '_blank', 'noopener,noreferrer')}>
                            <FaYoutube className="menu-icon" /> Subscribe for more!
                        </button>
                    </div>
                </div>
            )}
            {showQuestionModal && (
                <div className="modal" onClick={(event) => handleOutsideClick(event, () => setShowQuestionModal(false))}>
                    <div className="modal-content">
                        <h2>Let us know if you have any questions by emailing us at contact@demystify-ai.org . Thanks!</h2>
                        <br />
                        <div>
                            <button type="cancel" onClick={() => setShowQuestionModal(false)}>Return to Chat</button>
                        </div>
                    </div>
                </div>
            )}
            {showProfileModal && (
                <div className="modal" onClick={(event) => handleOutsideClick(event, () => setShowProfileModal(false))}>
                    <div className="modal-content">
                        <h2>My Profile</h2>
                        <form>
                            <label>First Name</label>
                            <input
                                type="text"
                                placeholder="First Name *"
                                value={ownerDetails.firstName}
                                disabled
                            />
                            <label>Last Name</label>
                            <input
                                type="text"
                                placeholder="Last Name *"
                                value={ownerDetails.lastName}
                                disabled
                            />
                            <label>Email</label>
                            <input
                                type="email"
                                placeholder="Email *"
                                value={ownerDetails.email}
                                disabled
                            />
                            <label>Job Title</label>
                            <input
                                type="text"
                                placeholder="Job Title *"
                                value={ownerDetails.title}
                                disabled
                            />
                            <label>Industry</label>
                            <input
                                type="text"
                                placeholder="Industry *"
                                value={ownerDetails.industry}
                                disabled
                            />
                        </form>
                        <button type="cancel" onClick={() => setShowProfileModal(false)}>Return to Chat</button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Sidebar;