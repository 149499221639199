import React, { useState, useEffect, useRef } from 'react';
import './App.css';
import ChatInput from "./components/ChatInput";
import ChatMessage from "./components/ChatMessage";
import ChatVideoEmbedding from "./components/ChatVideoEmbedding";
import ChatStatusIndicator from "./components/ChatStatusIndicator";
import Loading from "./components/Loading";
import Login from "./components/Login";
import ChatOption from "./components/ChatOption";
import ChatVideoOption from "./components/ChatVideoOption";
import Sidebar from './components/Sidebar';
import ScrollDownButton from './components/ScrollDownButton';
import VideoModal from './components/VideoModal';
import { useThread } from './hooks/useThread';
import { useRunPolling } from './hooks/useRunPolling';
import { useRunRequiredActionsProcessing } from './hooks/useRunRequiredActionsProcessing';
import { useRunStatus } from './hooks/useRunStatus';
import { postMessage, logInteraction } from "./services/api";

const videos = [
    "Show me a video about why I should be using AI in 2024.",
    "Show me a video about how to Create my AI Agency Website.",
    "Show me a video about how to Sell AI Appointment Setters.",
    "Show me a video about choices That Determines my AI Agencys Fate.",
    "Show me a video about how tomake $140k with AI in 6 Months."
];

const askBrandonPrompts = [
    "Hey Brandon, I am thinking about starting my own business, what advice can you give me?",
    "Hey Brandon, I am interested in learning more about AI, where should I start?",
    "Hey Brandon, I want to be a software engineer, what programming languages should I learn?",
    "Hey Brandon, I have trouble staying motivated, how do you stay motivated?",
    "Hey Brandon, I want to meet more people, what advice can you give me?"
];

function App() {
    const [run, setRun] = useState(undefined);
    const [isSending, setIsSending] = useState(false);
    const [showLogin, setShowLogin] = useState(false); // State to control the visibility of the Login modal
    const { threadId, messages, setActionMessages, clearThread } = useThread(run, setRun);
    const [isLoggedIn, setIsLoggedIn] = useState(false); // Manage login state
    useRunPolling(threadId, run, setRun);
    useRunRequiredActionsProcessing(run, setRun, setActionMessages);
    const { status, processing } = useRunStatus(run);
    const ownerDetails = getOwnerDetailsFromLocalStorage();
    const chatContainerRef = useRef(null);
    const [isAtBottom, setIsAtBottom] = useState(true);
    const [showScrollDownButton, setShowScrollDownButton] = useState(false);
    const [showVideoModal, setShowVideoModal] = useState(true); // State for controlling the video modal
    const [currentIndex, setCurrentIndex] = useState(0); // Index for the messages and quizzes

    useEffect(() => {
        // Check if user details exist in local storage to manage login state
        const userDetails = localStorage.getItem('userDetails');
        setIsLoggedIn(!!userDetails);
    }, []);

    const handleLoginComplete = () => {
        console.log("Login completed, hiding login modal.");
        setIsLoggedIn(true);
        setShowLogin(false); // This should definitely hide the login component
    };

    const sendMessage = (message) => {
        console.log("Sending message:", message);
        console.log("Current thread ID:", threadId);
        console.log("Is user logged in:", isLoggedIn);

        if (!threadId) {
            console.error("Cannot send message, thread ID is undefined.");
            return;
        }

        setIsSending(true);
        const ownerDetails = getOwnerDetailsFromLocalStorage();
        console.log("Owner details:", ownerDetails);

        postMessage(threadId, message, ownerDetails)
            .then(response => {
                setRun(response);
                // logInteraction(threadId, message, response, ownerDetails); // Log the interaction
                console.log("Message sent successfully:", response);
            })
            .catch(error => {
                console.error("Error sending message:", error);
            })
            .finally(() => {
                setIsSending(false);
                scrollToBottom();
                setIsAtBottom(true);
            });
    };

    const handleLoginStart = () => {
        if (!isLoggedIn) {
            setShowLogin(true);  // Show the login component
            const hardcodedMessage = "Let's Go!";
            sendMessage(hardcodedMessage);
            setShowVideoModal(true); // Show the video modal
            return;
        }
    };

    const handleVideoRequest = () => {
        const video = videos[currentIndex];
        sendMessage(video);
        setCurrentIndex((currentIndex + 1) % videos.length); // Update the index to the next message
    };

    const handleQuizRequest = () => {
        const quizPrompt = 'Quiz Me';
        sendMessage(quizPrompt);
    };

    const handleAskBrandonRequest = () => {
        const askBrandonPrompt = askBrandonPrompts[currentIndex];
        sendMessage(askBrandonPrompt);
        setCurrentIndex((currentIndex + 1) % askBrandonPrompts.length); 
    };

    const handleScroll = () => {
        const container = chatContainerRef.current;
        if (container) {
            const isBottom = container.scrollHeight - container.scrollTop <= container.clientHeight + 1;
            setIsAtBottom(isBottom);
            setShowScrollDownButton(!isBottom);
        }
    };

    const scrollToBottom = () => {
        const container = chatContainerRef.current;
        if (container) {
            container.scrollTo({
                top: container.scrollHeight,
                behavior: 'smooth'
            });
        }
        setShowScrollDownButton(false);
    };

    useEffect(() => {
        const container = chatContainerRef.current;
        if (container) {
            container.addEventListener('scroll', handleScroll);
        }
        return () => {
            if (container) {
                container.removeEventListener('scroll', handleScroll);
            }
        };
    }, []);

    let messageList = messages
        .toReversed()
        .filter((message) => message.hidden !== true)
        .map((message) => {
            if (message.role === "video_embedding") {
                return <ChatVideoEmbedding
                    url={message.content}
                    key={message.id}
                />
            } else {
                return <ChatMessage
                    message={message.content}
                    role={message.role}
                    key={message.id}
                    firstName={ownerDetails.firstName ? ownerDetails.firstName : "User"}
                    lastName={ownerDetails.lastName ? ownerDetails.lastName : " "}
                />
            }
        });

    return (
        <div className="app-container flex">
            <Sidebar />
            <div className="chat-container flex flex-col grow">
                {/* <Header onNewChat={clearThread} /> */}
                <div className="flex flex-col-reverse grow overflow-scroll" ref={chatContainerRef}>
                    {status !== undefined && <ChatStatusIndicator status={status} />}
                    {processing && <Loading />}
                    {messageList}
                </div>
                {!showLogin && !isLoggedIn && (
                    <>
                        <ChatOption onLoginStart={handleLoginStart} />
                        <VideoModal 
                            isOpen={showVideoModal} 
                            onClose={() => setShowVideoModal(false)} 
                            url="https://www.youtube.com/embed/B5KVe6vgxVk?autoplay=1&rel=0" 
                            refreshOnClose={false}
                        />
                    </>
                )}
                {!showLogin && isLoggedIn && (
                    <>
                        <ChatVideoOption 
                            onVideoRequest={handleVideoRequest}
                            onQuizRequest={handleQuizRequest}
                            onAskBrandonRequest={handleAskBrandonRequest}
                        />
                    </>
                )}
                <div className="my-1">
                    <ChatInput
                        onSend={(message) => {
                            setIsSending(true);
                            sendMessage(message);
                        }}
                        isSending={isSending}
                        disabled={processing || isSending || !isLoggedIn && !showLogin} // Disable input if the login screen is visible or message is sending
                    />
                </div>
                {showScrollDownButton && !isAtBottom && <ScrollDownButton onClick={scrollToBottom} />}
                {showLogin && (
                    <Login onComplete={handleLoginComplete} />
                )}
            </div>
        </div>
    );
}

function getOwnerDetailsFromLocalStorage() {
    const details = localStorage.getItem('userDetails');
    // Define default values for owner details
    const defaultOwnerDetails = {
        firstName: "User",
        lastName: "",
        email: "user@test.org",
        title: "",
        industry: ""
    };

    if (details) {
        try {
            return JSON.parse(details);
        } catch (error) {
            console.error("Failed to parse owner details from local storage:", error);
            return defaultOwnerDetails; // Return default object in case of JSON parse error
        }
    }
    return defaultOwnerDetails;
}

export default App;