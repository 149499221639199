import React from 'react';
import { FaArrowDown } from 'react-icons/fa'; // Importing Font Awesome Arrow Down icon
import './ScrollDownButton.css'; // Ensure this file exists and is correctly linked

const ScrollDownButton = ({ onClick }) => {
    return (
        <button className="scroll-down-button" onClick={onClick}>
            <FaArrowDown size={24} />
        </button>
    );
};

export default ScrollDownButton;