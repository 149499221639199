import React from 'react';

function ChatVideoOption({ onVideoRequest, onQuizRequest, onAskBrandonRequest }) {
    return (
        <div className="flex justify-right p-2 space-x-2">
            {/* <button 
                className="bg-slate-600 text-slate-200 py-2 px-4 rounded"
                onClick={onVideoRequest}
            >
                Show me a Video
            </button>
            <button 
                className="bg-slate-600 text-slate-200 py-2 px-4 rounded"
                onClick={onQuizRequest}
            >
                Quiz Me
            </button> */}
            <button 
                className="bg-slate-600 text-slate-200 py-2 px-4 rounded"
                onClick={onAskBrandonRequest}
            >
                Ask Brandon
            </button>
        </div>
    );
}

export default ChatVideoOption;
