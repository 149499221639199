import React, { useState, useEffect, useRef } from 'react';
import { MdSend } from 'react-icons/md';

export default function ChatInput({ disabled, onSend, isSending }) {
    const [message, setMessage] = useState("");
    const inputRef = useRef(null);

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    }, []);

    const LoadingModal = ({ isOpen }) => {
        if (!isOpen) return null;
        return (
            <div className="fixed inset-0 bg-gray-900 bg-opacity-75 flex items-center justify-center">
                <div className="loading-modal-content blue rounded-lg p-4 text-center">
                    <img 
                        src="https://uploads-ssl.webflow.com/666cc36fb532af19d57ce7fa/667b09e8ea382d4304fecb6d_favicon-32x32.png" 
                        alt="Loading..." 
                        className="loading-logo"
                    />
                    <p>Thinking...</p>
                </div>
            </div>
        );
    };

    return (
        <>
            <LoadingModal isOpen={isSending} />
            <form
                className="border-2 border-slate-400 rounded-lg p-2 flex flex-row m-2 text-slate-50"
                onSubmit={(e) => {
                    e.preventDefault();
                    if (!message.trim()) return; // Prevent sending empty messages
                    onSend(message);
                    setMessage(""); // Clear the message input after send
                    if (inputRef.current) {
                        inputRef.current.focus(); // Refocus input after sending
                    }
                }}
                autoComplete="off"
            >
                <input
                    ref={inputRef}
                    name="message"
                    placeholder={isSending ? "Please wait, thinking..." : "Ask me anything regarding AI..."}  // Conditional placeholder text
                    className="w-full bg-slate-800 focus:outline-none"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    disabled={disabled} // Use disabled prop to disable input
                />
                <button
                    disabled={disabled} // Use disabled prop to disable button
                    className="bg-slate-500 text-white font-bold py-2 px-4 rounded hover:bg-blue-400 disabled:bg-slate-600 disabled:text-slate-400"
                >
                    <MdSend/>
                </button>
            </form>
        </>
    );
}
