import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ChatVideoEmbedding from './ChatVideoEmbedding';
import './VideoModal.css';

const VideoModal = ({ isOpen, onClose, url, refreshOnClose }) => {
    const [showCloseButton, setShowCloseButton] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            setShowCloseButton(true);
        }, 12000);

        return () => clearTimeout(timer);
    }, []);

    const handleClose = () => {
        onClose();
        if (refreshOnClose) {
            window.location.reload(); // Refresh the page
        }
    };

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-gray-900 bg-opacity-75 flex items-center justify-center z-50">
            <div className="rounded-lg p-4 text-center relative max-w-6xl w-full">
                {showCloseButton && (
                    <button
                        onClick={handleClose}
                        className="bg-slate-400 hover:bg-slate-700 text-white font-bold py-2 px-4 rounded-full absolute top-0 transform -translate-y-1/2 left-1/2 -translate-x-1/2"
                    >
                        Close
                    </button>
                )}
                <ChatVideoEmbedding url={url} fromModal={true} />
            </div>
        </div>
    );
};

VideoModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    url: PropTypes.string.isRequired,
    refreshOnClose: PropTypes.bool, // New prop to determine if the page should refresh
};

VideoModal.defaultProps = {
    refreshOnClose: false, // Default value for the new prop
};

export default VideoModal;
