export default function ChatStatusIndicator({status}) {
    return (
        <div className="flex justify-center">
            <div className="flex flex-row items-center text-blue-300">
                <div className="m-2 animate-spin">
                    <img src="https://uploads-ssl.webflow.com/666cc36fb532af19d57ce7fa/667b09e8ea382d4304fecb6d_favicon-32x32.png" alt="Logo" style={{ width: '40px', height: '40px' }} />
                </div>
                <div>{status}</div>
            </div>
        </div>
    );
}