import React from 'react';
import PropTypes from 'prop-types';
import { FaYoutube } from "react-icons/fa";

const ChatVideoEmbedding = ({ url, fromModal }) => {
    const videoId = url.split('/').pop();
    const embedUrl = `https://www.youtube.com/embed/${videoId}`;

    return (
        <div className={`flex ${fromModal ? 'justify-center' : 'flex-row'} mx-2 my-3`}>
            {!fromModal && (
                <div className="rounded-full h-8 w-8 bg-pink-600 flex items-center justify-center font-semibold text-slate-50 shrink-0">
                    <FaYoutube />
                </div>
            )}
            <div className="p-1 ml-2">
                {!fromModal && (
                    <div className="flex-col">
                        <p className="font-semibold text-slate-400">Video</p>
                    </div>
                )}
                <iframe
                    width={fromModal ? "301" : "301"}
                    height={fromModal ? "200" : "200"}
                    src={embedUrl}
                    title="YouTube video player"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    frameBorder="0"
                ></iframe>
            </div>
        </div>
    );
};

ChatVideoEmbedding.propTypes = {
    url: PropTypes.string.isRequired,
    fromModal: PropTypes.bool,
};

export default ChatVideoEmbedding;
