export const API_URL = process.env.REACT_APP_API_URL;

export const createNewThread = async () => {
    try {
        let response = await fetch(`${API_URL}/api/new`, {
            method: "POST"
        })
        console.log(process.env.REACT_APP_API_URL)
        return response.json()
    } catch (err) {
        console.log(err.message)
    }
}


export const createNewUser = async (userData) => {
    try {
        let response = await fetch(`${API_URL}/api/new_user`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(userData) // Sending the user data as JSON
        });
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        return await response.json(); // Parses the JSON response
    } catch (err) {
        console.error("Error creating new user:", err.message);
        throw err; // Rethrow to let caller handle the error too
    }
}

export const fetchThread = async (threadId) => {
    try {
        let response = await fetch(`${API_URL}/api/threads/${threadId}`)
        return response.json()
    } catch (err) {
        console.log(err.message)
    }
}

export const fetchRun = async (threadId, runId) => {
    try {
        let response = await fetch(`${API_URL}/api/threads/${threadId}/runs/${runId}`)
        return response.json()
    } catch (err) {
        console.log(err.message)
    }
}

export const postMessage = async (threadId, message, ownerDetails) => {
    try {
        let response = await fetch(`${API_URL}/api/threads/${threadId}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                content: message,
                user: ownerDetails
            })
        })
        return response.json()
    } catch (err) {
        console.log(err.message)
    }
}

export const logInteraction = async (threadId, message, bot_response, ownerDetails) => {
    try {
        console.log("Message Sent:", message);
        console.log("Owner Details:", ownerDetails);
        console.log("Bot Response:", bot_response);
        let response = await fetch(`${API_URL}/api/log_chats`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                content: message,
                user: ownerDetails,
                resp: bot_response,
                threadid: threadId
            })
        })
        return response.json()
    } catch (err) {
        console.log(err.message)
    }
}

export const postToolResponse = async (threadId, runId, toolResponses) => {
    try {
        let response = await fetch(`${API_URL}/api/threads/${threadId}/runs/${runId}/tool`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(toolResponses)
        })
        return response.json()
    } catch (err) {
        console.log(err.message)
    }
}