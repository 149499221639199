// src/utils/getMetrics.js
import UAParser from 'ua-parser-js';
import axios from 'axios';

export const getMetrics = async () => {
    const parser = new UAParser();
    const result = parser.getResult();
    const { browser, device, os } = result;

    // Fetch the IP address using ipify
    const response = await axios.get('https://api.ipify.org?format=json');
    const ip = response.data.ip;

    return {
        browser: browser.name,
        browserVersion: browser.version,
        deviceType: device.type || 'Desktop',
        deviceVendor: device.vendor || 'Unknown',
        deviceModel: device.model || 'Unknown',
        osName: os.name,
        osVersion: os.version,
        ip
    };
};
