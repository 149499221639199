import { useEffect } from 'react';
import { postToolResponse } from "../services/api";

export const useRunRequiredActionsProcessing = (run, setRun, setActionMessages) => {
    useEffect(() => {
        if (run?.status === "requires_action") {
            let response = [];
            let actionMessages = [];
            for (const tool_call of run.required_action.submit_tool_outputs.tool_calls) {
                if (tool_call.function.name === "embed_youtube_video") {
                    response.push({
                        tool_call_id: tool_call.id,
                        output: JSON.stringify({ success: true })
                    });

                    const url = JSON.parse(tool_call.function.arguments).url;
                    const videoId = extractYouTubeVideoID(url);
                    if (videoId) {
                        const embedUrl = `https://www.youtube.com/embed/${videoId}`;
                        actionMessages.push({
                            id: `video_embedding_${videoId}_${Date.now()}`,
                            content: embedUrl,
                            role: "video_embedding",
                            created_at: Math.floor(Date.now() / 1000)
                        });
                        console.log(actionMessages);
                    }
                }
            }
            setActionMessages(actionMessages);
            postToolResponse(run.thread_id, run.run_id, response).then(setRun);
        }
    }, [run, setRun, setActionMessages]);
};

const extractYouTubeVideoID = (url) => {
    console.log(url);
    const regex = /(?:youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?\/\s]{11})/i;
    const match = url.match(regex);
    return match ? match[1] : null;
};
